.container {
  position: fixed;
  top: 80px;
  right: 20px;
  z-index: 10000;
}

.menuButton {
  background: none;
  border: none;
  cursor: pointer;
  padding: 8px;
  border-radius: 50%;
  /* background: linear-gradient(266.9deg, #6442ec 0%, #5f52f1 100%),
    linear-gradient(90deg, rgba(137, 96, 255, 0) 0%, #8960ff 100%); */
  background: linear-gradient(135deg, #614385 0%, #516395 50%, #336699 100%);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s;
}

.menuIcon {
  font-size: 15px;
}

.popup {
  position: absolute;
  top: 100%;
  right: 0;
  margin-top: 10px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  min-width: 200px;
  overflow: hidden;
  animation: slideIn 0.3s ease;
}

.popupHeader {
  padding: 12px 16px;
  background-color: #f5f5f5;
  font-weight: bold;
  color: #5f52f1;
  border-bottom: 1px solid #eee;
}

.popupContent {
  padding: 8px 0;
}

.menuItem {
  width: 100%;
  height: 40px;
  text-align: left;
  background: none;
  border: none;
  transition: background-color 0.2s;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
}
.menuItemEvents {
  width: 100%;
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: space-around;
  padding-left: 4px;
  padding-right: 4px;
}
.menuItemtext {
  font-weight: bold;
  color: #5f52f1;
}
.menuItem:hover {
  background-color: #f5f5f5;
}

@keyframes slideIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
