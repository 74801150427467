.container {
  width: 100%;
  min-height: 130px;
  height: fit-content;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-around;
  align-items: center;
}
/* .element {
  margin-bottom: 15px;
} */
.button {
  width: 303px;
  height: 48px;
  border-radius: 32px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.btnIcons {
  width: 24px;
  height: 24px;
  object-fit: contain;
  margin-right: 10px;
}
.apple {
  background: #ffffff1a;
}
.google {
  background: #ffffff;
  border: 1.5px solid #c4c8d9;
}
.facebook {
  background: #1877f2;
}
.whiteText {
  font-family: Inter;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: center;
  color: #ffffff;
}
.blackText {
  font-family: Inter;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: center;
  color: #151320;
}
@media (min-width: 768px) and (max-width: 1439px) {
  .button {
    width: 624px;
    height: 48px;
  }
}
@media (min-width: 1440px) {
  .button {
    width: 364px;
    height: 48px;
  }
}
