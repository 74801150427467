.container {
  width: 303px;
  height: 102px;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  align-items: center;
}
.title {
  font-family: Inter;
  font-weight: 700;
  font-size: 24px;
  line-height: 33.6px;
  letter-spacing: 0%;
  text-align: center;
  color: #ffffff;
}
.subTitle {
  font-family: Inter;
  font-weight: 700;
  font-size: 24px;
  line-height: 33.6px;
  letter-spacing: 0%;
  text-align: center;
  color: #ffffff;
}
