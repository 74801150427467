.container {
  margin: 0 auto;
  margin-top: 100px;
  width: 95%;
  height: 340px;
  background: linear-gradient(180deg, #3e3a54 0%, #120b3b 100%);
  border-radius: 12px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  overflow-y: scroll;
  box-sizing: border-box;
  -ms-overflow-style: none;
  scrollbar-width: none;
  position: relative;
  z-index: 10000;
}

.container::-webkit-scrollbar {
  display: none;
}

.modalContainer {
  width: 95%;
  height: 280px;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-around;
  align-items: center;
}

.modalText {
  width: 95%;
  height: fit-content;
  color: var(--white, #fff);
  text-align: left;
  font-size: 16px;
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.continueButton {
  width: 80%;
  height: 48px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  border: none;
  border-radius: 8px;
  background: var(--primary, #1a4bff);
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #ffffff;
  cursor: pointer;
}
.continueButton:hover {
  background: #1745ea;
}
.continueButton:active {
  background: #204ffa;
}

@media screen and (min-width: 500px) {
  .container {
    width: 400px;
  }
}
